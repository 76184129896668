import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import PreviewGrid from '../components/preview-grid'
import Seo from '../components/seo'
import Layout from '../containers/layout'
import { filterOutDocsWithoutSlugs, mapEdgesToNodes } from '../lib/helpers'

const IndexPage = ({ data, errors }) => {
  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
    : []
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : []
  const lazyHackNodes = (data || {}).lazyHacks
    ? mapEdgesToNodes(data.lazyHacks).filter(filterOutDocsWithoutSlugs)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <Seo title={site.title} description={site.description} keywords={site.keywords} />
      <h1 hidden>Welcome to {site.title}</h1>
      {postNodes && (
        <PreviewGrid
          title='Latest blog posts'
          nodes={postNodes}
          type='blog'
          browseMoreHref='/blog/'
        />
      )}
      {/* {lazyHackNodes && (
        <PreviewGrid
          title='Latest lazy hacks'
          nodes={lazyHackNodes}
          type='lazy-hacks'
          browseMoreHref='/lazy-hacks/'
        />
      )} */}
      {projectNodes && (
        <PreviewGrid
          title='Latest projects'
          nodes={projectNodes}
          type='projects'
          browseMoreHref='/projects/'
        />
      )}
    </Layout>
  )
}

export default function IndexPageQuery () {
  return (
    <StaticQuery
      query={graphql`
        query IndexPageQuery {
          site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
          }

          projects: allSanityProject(
            limit: 6, sort: { fields: [_updatedAt], order: DESC },
            filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
          ) {
            edges {
              node {
                id
                mainImage {
                  crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                  asset {
                    _id
                  }
                  alt
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }

          posts: allSanityPost(
            limit: 6, sort: { fields: [publishedAt], order: DESC }
            filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
          ) {
            edges {
              node {
                id
                publishedAt
                mainImage {
                  crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                  asset {
                    _id
                  }
                  alt
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }

          lazyHacks: allSanityLazyhack(
            limit: 6, sort: { fields: [publishedAt], order: DESC }
            filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
          ) {
            edges {
              node {
                id
                publishedAt
                mainImage {
                  crop {
                    _key
                    _type
                    top
                    bottom
                    left
                    right
                  }
                  hotspot {
                    _key
                    _type
                    x
                    y
                    height
                    width
                  }
                  asset {
                    _id
                  }
                  alt
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }
        }
      `}
      render={data => <IndexPage data={data} />}
    />
  )
}
